import { get_containing, get_containing_filt, get_random } from "./utils";
import PeopleSection from "./peoplesection";
import BookSection from "./booksection";
import CategorySidebar from "./categorysidebar";
import Quote from "./quote";
import SEO from "../components/seo";
import categories from "../categories";

function PeopleRoot({ people, other_people, other_books, area, pagination }) {
  let readable_area = area
    ? categories["person_url"][area]
    : "Todas as Pessoas";

  return (
    <div className="px-10 md:px-24 xl:px-60 gap-y-5 flex flex-col">
      <SEO
        title={"Recomendações de livros - " + readable_area}
        description="Não sabe o que ler? Descubra os livros recomendados pelas pessoas mais bem sucedidas do mundo."
      />
      <div className="grid lg:grid-cols-2 grid-cols-1 text-center lg:text-left">
        <div className="text-2xl lg:text-4xl font-light text-blue-800 ">
          <h1>{readable_area}</h1>
        </div>
        <div className="text-xl font-light text-blue-800 my-8 lg:my-16 place-items-center grid">
          <div className="w-3/4">
            <h1>
              Aqui voce vai descobrir o que as pessoas mais bem sucedidas do
              mundo estão lendo
            </h1>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-6 pt-5">
        <div className="col-span-1 lg:col-span-4">
          <PeopleSection
            people={people}
            cta="Todas as pessoas"
            pagination={pagination}
          />
        </div>
        <div className="col-span-1 lg:col-span-2">
          <CategorySidebar
            categories={categories["person_categories"]}
            base_url="setor"
          />
        </div>
      </div>
      <PeopleSection
        people={other_people}
        cta="Pessoas em destaque"
        className="mt-5 lg:mt-20"
      />
      <BookSection
        books={other_books}
        cta="Livros em destaque"
        className="lg:mt-20"
      />
      <Quote />
    </div>
  );
}

export default PeopleRoot;
